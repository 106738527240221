import {lazy} from 'react'
import { createBrowserRouter } from "react-router-dom";

const Root = lazy(()=> import ('../pages/Root'))
const Homepage = lazy(()=> import ('../pages/Homepage'))
const Windows = lazy(()=> import ('../pages/Windows'))
const Doors = lazy(()=> import ('../pages/Doors'))
const Sunrooms = lazy(()=> import ('../pages/Sunrooms'))
const PassiveSystem = lazy(()=> import ('../pages/PassiveSystem'))
const Company = lazy(()=> import ('../pages/Company'))
// const Millwork = lazy(()=> import ('../pages/Millwork'))
const Gallery = lazy(()=> import ('../pages/Gallery'))
const Contact = lazy(()=> import ('../pages/Contact'))
const Privacy = lazy(()=> import ('../pages/Privacy'))

const routes = [
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <Homepage />,
      },
      {
        path: "/windows",
        element: <Windows />,
      },
      {
        path: "/doors",
        element: <Doors />,
      },
      {
        path: "/sunrooms",
        element: <Sunrooms />,
      },
      {
        path: "/passive-system",
        element: <PassiveSystem />,
      },
      // {
      //   path: "/millwork",
      //   element: <Millwork />,
      // },
      {
        path: "/company",
        element: <Company />,
      },
      {
        path: "/gallery",
        element: <Gallery />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/privacy",
        element: <Privacy />,
      },
    ],
  },
];

const router = createBrowserRouter(routes);
export default router;
